/* InterviewPrepForm.css */

/* Global Container */
.interview-prep-container {
    max-width: 600px;
    max-height: 100%;
    margin: 0 auto;
    background: #f5f5f7;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
  }
  
  /* Form Heading */
  .form-heading {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Input Container */
  .input-container {
    margin-bottom: 20px;
  }
  
  /* Input Field */
  .input-field {
    width: 95%;
    padding: 12px;
    border-radius: 10px;
    border: none;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    font-size: 1rem;
    color: #333;
    margin-top: 8px;
  }
  .input-field:focus, .textarea-field:focus {
    outline: none;
    border: 2px solid #6e8efb; /* Add an outline on focus */
    box-shadow: 0 0 5px rgba(110, 142, 251, 0.5); /* Subtle glow effect */
  }
  
  /* Textarea Field */
  .textarea-field {
    width: 70%;
    padding: 15px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    font-size: 1rem;
    color: #333;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
    margin-top: 8px;
    resize: none;
  }
  
  /* Browse Button */
  .browse-button {
    cursor: pointer;
   /* background: linear-gradient(135deg, #6e8efb, #a777e3);*/
    background-color: #6e8efb;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    float: right;
    margin-left: 10px;
    width: 15%;
    height: 60px;
  }
  
  /* Error Message */
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
  }
  
  /* Navigation Buttons */
  .back-button, .submit-button,.next-button, .clear-button{
    background-color: #6e8efb;
    border: none;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 50%;
    margin-bottom: 10px;
  }
  .choose-another-option-button, .start-over-button{
    background-color: #6e8efb;
    border: none;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 46%;
    margin-bottom: 10px;
  }

  .next-button:hover, .back-button:hover, .submit-button:hover, .clear-button:hover, .choose-another-option-button:hover, .start-over-button:hover {
    background-color: #a777e3;
  }
  
  /* Response Container */
  .response-container {
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }
  
  .response-heading {
    font-size: 1.4rem;
    color: #333;
  }
  
  .response-text {
    font-size: 1rem;
    color: #333;
  }

  .formatted-response h3 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  
  .formatted-response p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .button-group {
    margin-top: 2rem;
  }
  
  .button-group button {
    margin-right: 1rem;
  }
  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #222;
  }
  /* Style for the logo image at the top */
.header-image {
  text-align: center;
  margin-bottom: 20px;
}

.top-logo {
  width: 100%; /* Adjust width */
  height: auto;
}
/* Wrap code blocks */
.formatted-response pre {
  white-space: pre-wrap; /* Ensure the code inside the <pre> tag wraps */
  word-wrap: break-word; /* Break long words */
  overflow-x: auto; /* Add horizontal scrolling for large blocks */
  background-color: #f5f5f5; /* Optional: Add a background for code blocks */
  padding: 10px; /* Optional: Padding for readability */
  border-radius: 5px; /* Optional: Rounded corners */
  font-size: 0.9rem; /* Optional: Smaller font size */
}

.formatted-response code {
  white-space: pre-wrap; /* Ensure the code inside the <code> tag wraps */
  word-wrap: break-word; /* Break long words */
}
